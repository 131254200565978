/*
 * @Auth: linjituan
 * @Date: 2021-10-27 12:14:34
 * @LastEditors: linjituan
 * @LastEditTime: 2021-10-27 15:22:04
 */
import request from '@/utils/request'

// 查询车辆事件记录列表
export function listEventRecord(query) {
  return request({
    url: '/iot/eventRecord/list',
    method: 'get',
    params: query
  })
}
