<!--
 * @Auth: linjituan
 * @Date: 2021-10-27 10:13:26
 * @LastEditors: linjituan
 * @LastEditTime: 2022-11-14 11:38:29
-->
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆名称">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="发生时间">
                <a-range-picker
                  style="width: 100%"
                  v-model="dateRange"
                  :ranges="{ 今天: [moment(), moment()], 这个月: [moment(), moment().endOf('month')] }"
                  show-time
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="operId"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="loadVehiclesMap(record)"> 视频 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <monitor-camera-history-modal
      :info="selectedVehicleInfo"
      v-if="cameraHistoryVisible"
      :camera-history-visible="cameraHistoryVisible"
      :defaultDateRange="defaultDateRange"
      @closeModalCameraHistory="closeModalCameraHistory"
      :position="{ top: '1%', left: '25%' }"
      source="eventVideo"
    />
  </page-header-wrapper>
</template>

<script>
import { listEventRecord } from '@/api/iot/eventRecord'
import moment from 'moment'
import { parseDateTime } from '@/utils/ruoyi'
import { mapState } from 'vuex'
import MonitorCameraHistoryModal from '@/views/monitor/monitorForm/vehicle/funcList/modules/MonitorCameraHistoryModal'
import { getOneVehicleInfo } from '@/api/iot/vehicle'
export default {
  name: 'SuddenStopLog',
  components: { MonitorCameraHistoryModal },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      loading: false,
      // 非多个禁用
      multiple: true,
      total: 0,
      // 状态数据字典
      statusOptions: [],
      typeOptions: [],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        vehicleId: this.$route.query.vehicleId,
        type: '4',
        beginSummaryDate: undefined,
        endSummaryDate: undefined
      },
      eventOptions: [],
      columns: [
        {
          title: '车辆名称',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时间',
          ellipsis: true,
          dataIndex: 'happenTime',
          customRender: (text, record, index) => {
            return record.happenTime ? parseDateTime(new Date(record.happenTime), 'yyyy-MM-dd HH:mm:ss:SSS') : ''
          },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      cameraHistoryVisible: false,
      selectedVehicleInfo: {},
      defaultDateRange: undefined,
      loadFlag: 1
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('vehicle_event_type').then((response) => {
      this.eventOptions = response.data
    })
  },
  computed: {
    ...mapState({
      allInfo: (state) => state.websocket.allInfo,
      websocketReady: (state) => state.websocket.websocketReady,
      allInfoReady: (state) => state.websocket.allInfoReady
    })
  },
  watch: {
    dateRange(val) {
      if (val) {
        this.queryParam.beginSummaryDate = val[0]
        this.queryParam.endSummaryDate = val[1]
      }
    }
  },
  methods: {
    async loadVehiclesMap(item) {
      getOneVehicleInfo({
        vehicleIds: item.vehicleId
      }).then((res) => {
        this.setDefaultDateRange(item.happenTime)
        this.selectedVehicleInfo = res.data
        this.cameraHistoryVisible = true
      })
    },
    closeModalCameraHistory() {
      this.cameraHistoryVisible = false
    },
    moment,
    /** 查询登录日志列表 */
    getList() {
      this.loading = true
      listEventRecord(this.addDateRange(this.queryParam, this.dateRange)).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 操作日志状态字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 操作日志类型字典翻译
    typeFormat(row, column) {
      return this.selectDictLabel(this.typeOptions, row.businessType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        vehicleId: undefined,
        type: '4',
        beginSummaryDate: undefined,
        endSummaryDate: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.operId)
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    setDefaultDateRange(dateStr) {
      // 设置视频查询时间
      const baseTime = 20 * 1000 // 前后20秒
      const date = moment(new Date(dateStr)).format()
      const str = moment('2021-10-29T18:35:51+08:00')
      this.defaultDateRange = [
        this.$route.query.textDate ? '2021-10-29T18:35:51+08:00' : date,
        this.$route.query.textDate ? str - baseTime : dateStr - baseTime
      ]
    }
  }
}
</script>
